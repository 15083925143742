export const COOKIE_INFO = {
  TOKEN: "token",
  SHOW_MONTH: "showMonth", // スケジュール一覧
  SHOW_HOUR: "showhour", // スケジュール一覧
  PER_HOUR: "perhour", // スケジュール一覧
  SCHEDULE_LIST_DATE: "scheduleListDate", // スケジュール一覧の日付
  REGISTER_STEP1: "registerStep1",
  REGISTER_EDIT_STEP1: "registerEditStep1",
  DISPOSER_STEP1: "disposerRegisterStep1",
  DISPOSER_EDIT_STEP1: "disposerRegisterEditStep1",
  COLLECTOR_STEP1: "collectorRegisterStep1",
  COLLECTOR_EDIT_STEP1: "collectorRegisterEditStep1",
  MESSAGE: "resultMessage", // 予約等したときのメッセージ
};

export const LOCAL_STORAGE_INFO = {
  USE_STORE_LIST: "useStoreList", // スケジュール一覧
  USE_STORE_LIST_CHECK: "useStoreListCheck", // スケジュール一覧
  USE_JWNET_ITEM_TYPE: "useJwnetItemType", // スケジュール一覧
  USE_JWNET_ITEM_TYPE_CHECK: "useJwnetItemTypeCheck", // スケジュール一覧
}

export const EXTERNAL_API_URL = {
  SEARCH_ADDRESS: "https://zipcloud.ibsnet.co.jp/api/search", // 住所検索
};

export const API_URL = {
  AUTH: {
    LOGIN: process.env.VUE_APP_API_ENDPOINT + "/login", // ログイン
    LOGOUT: process.env.VUE_APP_API_ENDPOINT + "/logout", // ログアウト
    AUTH: process.env.VUE_APP_API_ENDPOINT + "/authenticate", // 認証
    INIT_SETTING: process.env.VUE_APP_API_ENDPOINT + "/init/setting", // 本人確認
  },
  COMMON: {
    FILE_UPLOAD: process.env.VUE_APP_API_ENDPOINT + "/file/upload", // ファイルアップロード
    DISPOSAL_SITE_LIST: process.env.VUE_APP_API_ENDPOINT + "/selectoption/disposalsite", // 業者による処分事業場選択肢取得API
    GENERATE_STORE_LIST: process.env.VUE_APP_API_ENDPOINT + "/selectoption/generatestore", // 業者による排出事業場選択肢取得API
  },
  INFO: {
    ALL: process.env.VUE_APP_API_ENDPOINT + "/informations", //お知らせ一覧 /informations?isOnlyImportant=${isOnlyImportant}&page=${page}&pageSize=${pageSize}
    SINGLE: process.env.VUE_APP_API_ENDPOINT + "/information", // お知らせ詳細 /information/${id}
  },
  REMINDER: {
    SENDMAIL: process.env.VUE_APP_API_ENDPOINT + "/reminder/sendmail", // パスワードリマインダーメール送信
    SETTING: process.env.VUE_APP_API_ENDPOINT + "/reminder/setting", // パスワード再設定
  },
  PROFILE: {
    PROFILE: process.env.VUE_APP_API_ENDPOINT + "/profile", // プロフィール情報 GET-> 取得 PUT-> 編集
  },
  HOME: {
    GENERATEHOME: process.env.VUE_APP_API_ENDPOINT + "/generatehome", // 排出業者ホーム情報取得API
    COLLECTHOME: process.env.VUE_APP_API_ENDPOINT + "/collecthome", // 運搬業者ホーム情報取得API
    DISPOSALHOME: process.env.VUE_APP_API_ENDPOINT + "/disposalhome", // 処分業者ホーム情報取得API
  },
  MENU: {
    MENU: process.env.VUE_APP_API_ENDPOINT + "/menu", // メニュー取得
  },
  DOMAIN: {
    RECURRING: process.env.VUE_APP_API_ENDPOINT + "/recurringtype", // 繰り返し情報選択肢取得
    ACCOUNT_TYPE: process.env.VUE_APP_API_ENDPOINT + "/accountType", // アカウント権限種別取得API
    WASTE_UNIT: process.env.VUE_APP_API_ENDPOINT + "/wasteunit", // 廃棄物数量単位選択肢取得
    SHAPE: process.env.VUE_APP_API_ENDPOINT + "/shape", // 荷姿選択肢取得,
    DISPOSAL_WAY_TYPE: process.env.VUE_APP_API_ENDPOINT + "/disposal/waytype", // 処分方法選択肢取得
    PREFECTURES: process.env.VUE_APP_API_ENDPOINT + "/prefectures", // 都道府県選択肢取得
    CONTRACT_TYPE: process.env.VUE_APP_API_ENDPOINT + "/contracttype", // 契約プラン選択肢取得
    INVOICE_TYPE: process.env.VUE_APP_API_ENDPOINT + "/invoicetype", // 請求タイミング選択肢取得API
    INVOICE_METHOD_TYPE:
      process.env.VUE_APP_API_ENDPOINT + "/invoicemethodtype", // 請求方法選択肢取得API
    LEGAL_PERSONALITY_TYPE:
      process.env.VUE_APP_API_ENDPOINT + "/legalpersonalitytype", // 法人格選択肢取得
    HARMFUL_TYPE: process.env.VUE_APP_API_ENDPOINT + "/harmfultype", // 有害物質名選択肢取得API
    WASTE_TYPE: process.env.VUE_APP_API_ENDPOINT + "/wastetype", // 廃棄物区分選択肢取得API
    MANIFEST_TYPE: process.env.VUE_APP_API_ENDPOINT + "/manifesttype", // マニフェスト区分選択肢取得API
    JWNET_ITEM_TYPE: process.env.VUE_APP_API_ENDPOINT + "/jwnetitemtype", // 廃棄物種類選択肢取得
    JWNET_ITEM_TYPE_LICENCE: process.env.VUE_APP_API_ENDPOINT + "/jwnetitemtype/licence", // 許可書用、廃棄物種類選択肢取得
    CBA_JWNET_ITEMS: process.env.VUE_APP_API_ENDPOINT + "/cbaitem/jwnetitem/", // 廃棄物種類別CBA品目選択肢取得API /cbaitem/jwnetitem/{id}
    CBA_WASTE_NAME: process.env.VUE_APP_API_ENDPOINT + "/wastename/cbaitem/", // CBA品目別廃棄物名称選択肢取得API /wastename/cbaitem/{id}
    TERM_TYPE: process.env.VUE_APP_API_ENDPOINT + "/termtype", // 期間選択肢取得API
    COLLECT_WAY_TYPE: process.env.VUE_APP_API_ENDPOINT + "/collect/waytype", // 運搬方法選択肢取得API
    LABEL_TYPE: process.env.VUE_APP_API_ENDPOINT + "/labeltype", // ラベルタイプ選択肢取得API
    TARGET_QUANTITY_TYPE:
      process.env.VUE_APP_API_ENDPOINT + "/targetquantitytype", // 対象数量区分選択肢取得API
    WASTE_NAME: process.env.VUE_APP_API_ENDPOINT + "/wastename", // 廃棄物名称選択肢取得API
    WASTE_NAME_CBA_ITEM: (id) =>
      `${process.env.VUE_APP_API_ENDPOINT}/wastename/cbaitem/${id}`, // CBA品目別廃棄物名称選択肢取得API
  },
  SCHEDULE: {
    DETAIL: process.env.VUE_APP_API_ENDPOINT + "/schedule/", // スケジュール詳細取得 /schedule/{id}
    GENERATE_STORE: process.env.VUE_APP_API_ENDPOINT + "/generate/store/list", // 事業場選択肢取得
    DISPOSAL_STORE: process.env.VUE_APP_API_ENDPOINT + "/disposal/store/list", // 処分事業場選択肢取得
    LIST: process.env.VUE_APP_API_ENDPOINT + "/schedule/list", // スケジュール一覧取得
    RECURRING: process.env.VUE_APP_API_ENDPOINT + "/schedule/recurring/", // 繰り返し設定更新 /schedule/recurring/{id}
  },
  MANIFEST: {
    MEMBER_OPT:
      process.env.VUE_APP_API_ENDPOINT + "/manifest/registMemberOption/", // 担当者一覧取得 /manifest/registMemberOption/{storeId}
    CREATE: process.env.VUE_APP_API_ENDPOINT + "/manifest/create", // マニフェスト予約
    MULTIPLE_CREATE: process.env.VUE_APP_API_ENDPOINT + "/manifest/multipleRoute/create", // ルート複数登録用、マニフェスト予約
    EMPTY_CREATE: process.env.VUE_APP_API_ENDPOINT + "/manifest/emptycreate", // マニフェスト空登録
    JWNET_ITEM: process.env.VUE_APP_API_ENDPOINT + "/jwnetitemtype", // 廃棄物種類選択肢取得
    SEARCH: process.env.VUE_APP_API_ENDPOINT + "/manifests", // マニフェスト検索
    BULK_EDIT: process.env.VUE_APP_API_ENDPOINT + "/manifests", // マニフェスト予約情報一括編集API
    DETAIL: process.env.VUE_APP_API_ENDPOINT + "/manifests/", // マニフェスト情報取得API /manifests/{id}
    EDIT: process.env.VUE_APP_API_ENDPOINT + "/manifests/", // マニフェスト編集API /manifests/{id}
    COLLECT_REPORT:
      process.env.VUE_APP_API_ENDPOINT + "/manifest/collect/report/", // 運搬終了報告登録 /manifest/collect/report/{id}
    COLLECT_REPORT_EDIT:
      process.env.VUE_APP_API_ENDPOINT + "/manifest/collect/report/edit/", // 運搬終了報告修正 /manifest/collect/report/edit/{id}
    COLLECT_REPORT_DELETE:
      process.env.VUE_APP_API_ENDPOINT + "/manifest/collect/report/delete", // 運搬終了報告取消 /manifest/collect/report/delete/{id}
    STATUS_UPDATE: process.env.VUE_APP_API_ENDPOINT + "/manifest/status/update", // マニフェストステータス更新
    CBA_ITEMS: process.env.VUE_APP_API_ENDPOINT + "/cbaitems", // CBA品目選択肢取得
    COMPLETE_CARRY:
      process.env.VUE_APP_API_ENDPOINT + "/manifest/completionCarryReport/", // 運搬終了報告情報取得 /manifest/completionCarryReport/${id}
    TRANSPOTERS: process.env.VUE_APP_API_ENDPOINT + "/manifest/collect/members", // 運搬担当者選択肢取得
    CONFIRM_MEMBER:
      process.env.VUE_APP_API_ENDPOINT + "/quantity/confirmmember/", //数量確定者選択肢取得 /quantity/confirmmember/${id}
    REPORT_FIX_APPROVE:
      process.env.VUE_APP_API_ENDPOINT + "/manifest/reportfix/approve/", // 終了報告修正承認情報取得API /manifest/reportfix/approve/{id}
    PATCH_REPORT_FIX_APPROVE:
      process.env.VUE_APP_API_ENDPOINT + "/manifest/reportfix/approve/", // 終了報告修正承認API patch/manifest/reportfix/approve/{id}
    PROXY_GENERATE_COMPANY:
      process.env.VUE_APP_API_ENDPOINT + "/proxy/generate/company", // 代理登録排出事業者選択肢取得API
    MEMBER_OPT_GENERATE:
      process.env.VUE_APP_API_ENDPOINT + "/manifest/memberoption/", // 排出事業場担当者一覧取得API /manifest/memberoption/{generateStoreId}
    GENERATE_STORE: process.env.VUE_APP_API_ENDPOINT + "/generate/store/", // 排出事業者別排出事業場選択肢取得API /generate/store/{generateCompanyId}
    SEARCH_BY_COLLECT: process.env.VUE_APP_API_ENDPOINT + "/collect/manifests", // 運搬マニフェスト検索
    SEARCH_BY_DISPOSAL: process.env.VUE_APP_API_ENDPOINT + "/disposal/manifests", // 運搬マニフェスト検索
    DELIVERY_CHECKSHEET:
      process.env.VUE_APP_API_ENDPOINT + "/delivery/checksheet", // 受渡確認票出力API （POST）
    CONSTITUTION_ITEM: process.env.VUE_APP_API_ENDPOINT + "/constitutionitem", // 構成種類選択肢取得API
    GENERATOR_COLLECT_LIST:
      process.env.VUE_APP_API_ENDPOINT + "/generator/collect/list", // 運搬事業者選択肢取得API
    GENERATOR_DISPOSAL_LIST:
      process.env.VUE_APP_API_ENDPOINT + "/generator/disposal/list", // 処分事業者選択肢取得API
    DISPOSAL_SITE_LIST:
      process.env.VUE_APP_API_ENDPOINT + "/disposal/site/list", // 処分事業場選択肢取得API
    SELECTOPTION_DISPOSALSITE:
      process.env.VUE_APP_API_ENDPOINT + "/selectoption/disposalsite", //業者による処分事業場選択肢取得API
    SELECTOPTION_GENERATESTORE:
      process.env.VUE_APP_API_ENDPOINT + "/selectoption/generatestore",
    DISPOSAL_GENERATE_SELECTCOMPANY:
      process.env.VUE_APP_API_ENDPOINT + "/disposal/generate/selectcompany", //処分事業者にひもづく排出事業者選択取得API
    DISPOSAL_COLLECT_SELECTCOMPANY:
      process.env.VUE_APP_API_ENDPOINT + "/disposal/collect/selectcompany", //処分事業者にひもづく運搬事業者選択取得API
    SUMMARY: process.env.VUE_APP_API_ENDPOINT + "/manifests/summary", // マニフェストステータス件数取得API
    SUMMARY_COLLECT:
      process.env.VUE_APP_API_ENDPOINT + "/collect/manifests/summary/", // 運搬マニフェストステータス件数取得API /collect/manifests/summary/{searchConditionId}
    SUMMARY_DISPOSAL: process.env.VUE_APP_API_ENDPOINT + "/disposal/manifests/summary", // 処分マニフェストステータス件数取得API
    WDS_DOWNLOAD: process.env.VUE_APP_API_ENDPOINT + "/route/wdsfile/", // WDSファイルダウンロードAPI /route/wdsfile/{id}
    BUNDLE_ISSUE: process.env.VUE_APP_API_ENDPOINT + "/manifest/bundleissue", // マニフェスト一括交付API
    CSV_DOWNLOAD:
      process.env.VUE_APP_API_ENDPOINT + "/manifest/search/filedownload", // マニフェストCSVダウンロードAPI （POST）
    COLLECT_CSV_DOWNLOAD:
      process.env.VUE_APP_API_ENDPOINT +
      "/collect/manifest/search/filedownload", // 運搬マニフェストCSVダウンロードAPI （POST）
    EDIT_REQUEST_ID:
      process.env.VUE_APP_API_ENDPOINT + "/manifest/edit/request", // マニフェスト修正依頼情報取得API
    EDIT_APPROVE_ID: process.env.VUE_APP_API_ENDPOINT + "/manifest/fix/approve", // マニフェスト修正承認API
    CSV_UPLOAD: process.env.VUE_APP_API_ENDPOINT + "/manifest/csvupload",
    CSV_REGISTER: process.env.VUE_APP_API_ENDPOINT + "/manifest/csvregister",
    CANCEL_ALERT_FINAL_DISPOSAL_SITE_NG: process.env.VUE_APP_API_ENDPOINT + "/manifest/finaldisposalsite/alert/", // 最終処分場アラート解除API(/manifest/finaldisposalsite/alert/{manifestId})
    CSV_FORMAT: process.env.VUE_APP_API_ENDPOINT + "/manifest/csvformat",
    CSV_FORMAT_ITEMS: process.env.VUE_APP_API_ENDPOINT + "/manifest/csvformat/items",
    CSV_DOWNLOAD_BY_FORMAT: process.env.VUE_APP_API_ENDPOINT + "/manifest/csvformat/createcsv",
    COLLECT_CSV_DOWNLOAD_BY_FORMAT: process.env.VUE_APP_API_ENDPOINT + "/collect/manifest/csvformat/createcsv",
    DISPOSAL_CSV_DOWNLOAD_BY_FORMAT: process.env.VUE_APP_API_ENDPOINT + "/disposal/manifest/csvformat/createcsv",
    FILE_DOWNLOAD: process.env.VUE_APP_API_ENDPOINT + "/manifest/attachedfile/", // 添付ファイルダウンロードAPI（GET） /manifest/attachedfile/{id}
    MANIFEST_NO_GET_WAY: process.env.VUE_APP_API_ENDPOINT + "/manifests/manifestNo/getWay", //番号取得方法取得API
    GENERATE_MEMBER_GET_WAY: process.env.VUE_APP_API_ENDPOINT + "/manifest/generatemember/metainfo/", //マニフェスト引き渡し担当者メンバーの拡張情報取得API manifest/generatemember/metainfo/{storeid}
    RESERVE: process.env.VUE_APP_API_ENDPOINT + "/manifest/bundlereserve", //未予約マニフェスト予約API
    IS_JWNET_REFERRING: process.env.VUE_APP_API_ENDPOINT + "/manifest/isJwnetReferring", //JWNET連携中確認API
  },
  GENERATOR: {
    CREATE_CONTRACT: process.env.VUE_APP_API_ENDPOINT + "/generator/contract", // 排出事業者契約企業登録 step1
    CREATE_COMPANY: process.env.VUE_APP_API_ENDPOINT + "/generator/company", // 排出事業者登録 step2
    EDIT_CONTRACT: process.env.VUE_APP_API_ENDPOINT + "/generator/contract/", //排出事業者契約企業編集 step1  /generator/contract/{id}
    EDIT_COMPANY: process.env.VUE_APP_API_ENDPOINT + "/generator/company/", //排出事業者編集 step2  /generator/company/{generateContractId}
    CONTRACT_GROUP_LIST:
      process.env.VUE_APP_API_ENDPOINT + "/generator/contract/group/list", // 企業グループ選択肢取得
    CONTRACT_REGIST_COUNT:
      process.env.VUE_APP_API_ENDPOINT +
      "/generator/contract/registstatus/count", // 排出契約登録状況別件数取得
    CONTRACTOR_INFO: process.env.VUE_APP_API_ENDPOINT + "/generator/contract/", //排出事業者契約詳細取得   /generator/contract/{id}
    COMPLETE_MAIL:
      process.env.VUE_APP_API_ENDPOINT + "/generator/contract/sendmail", //準備完了メール送付
    CONTRACT_SEARCH: process.env.VUE_APP_API_ENDPOINT + "/generator/contract", //排出契約検索
    ACTIVE_STATUS: process.env.VUE_APP_API_ENDPOINT + "/generator/contract/", // 排出契約有効無効化API（PATCH） /generator/contract/{id}
    FACTORY_SELECT:
      process.env.VUE_APP_API_ENDPOINT + "/generate/factory/selectstore", //事業所別排出事業場選択肢取得API
    MANIFEST_CSV_REGIST_VALIDATE: process.env.VUE_APP_API_ENDPOINT + "/generate/manifest/csvregistvalidate", //マニフェストCSV一括予約バリデーションチェックAPI
    MANIFEST_CSV_REGIST: process.env.VUE_APP_API_ENDPOINT + "/generate/manifest/csvregist" //マニフェストCSV一括予約API
  },
  COLLECTOR: {
    POST: process.env.VUE_APP_API_ENDPOINT + "/collector", // 運搬事業者登録
    POST2: process.env.VUE_APP_API_ENDPOINT + "/collectfactory", // 運搬事業者登録 step2
    EDIT_COLLECT_COMPANY:
      process.env.VUE_APP_API_ENDPOINT + "/collect/company/", // 運搬事業者編集 /collect/company/{id}
    EDIT_COLLECT_FACTORY:
      process.env.VUE_APP_API_ENDPOINT + "/collect/factory/", // 運搬事業所編集 /collect/factory/{collectCompanyId}
    DETAIL: process.env.VUE_APP_API_ENDPOINT + "/collector/", // 運搬事業者情報取得 /collector/{id}    // 運搬事業者有効無効化API
    MUNICIPALITY:
      process.env.VUE_APP_API_ENDPOINT + "/carryCompany/municipality/", // 自治体取得 /carryCompany/municipality/{id}
    REGIST_COUNT:
      process.env.VUE_APP_API_ENDPOINT + "/collector/registstatus/count", // 運搬契約登録状況別件数取得
    CBACONTRACT_CREATE:
      process.env.VUE_APP_API_ENDPOINT + "/collector/cbacontract", // CBA契約登録
    CBACONTRACT_EDIT:
      process.env.VUE_APP_API_ENDPOINT + "/collector/cbacontract/", // CBA契約編集 /collector/cbacontract/{id}
    SENDMAIL: process.env.VUE_APP_API_ENDPOINT + "/collector/contract/sendmail", // 準備完了メール送付
    GENERATE_SELECT_COMPANY:
      process.env.VUE_APP_API_ENDPOINT + "/collect/generate/selectcompany", // 運搬に紐づく排出事業者選択肢取得API
    SELECT_FACTORY:
      process.env.VUE_APP_API_ENDPOINT + "/collect/generate/selectfactory/", // 運搬に紐づく排出事業所選択肢取得API /collect/generate/selectfactory/{generateCompanyId}
    DISPOSAL_LIST: process.env.VUE_APP_API_ENDPOINT + "/collect/disposal/list", // 運搬に紐づく処分事業者選択肢取得API
    CHECK_PROXY: process.env.VUE_APP_API_ENDPOINT + "/collector/check/proxy", // 代理登録可否判定API
    LICENCE_DOWNLOAD:
      process.env.VUE_APP_API_ENDPOINT + "/collect/licence/file/", // 運搬許可証ファイルダウンロードAPI /collect/licence/file/{id}
    CONTRACT: process.env.VUE_APP_API_ENDPOINT + "/collector/contract", // 代理登録先取得API
  },
  DISPOSER: {
    POST: process.env.VUE_APP_API_ENDPOINT + "/disposer", // 処分事業者登録
    POST2: process.env.VUE_APP_API_ENDPOINT + "/disposalsite", // 処分場登録
    EDIT: process.env.VUE_APP_API_ENDPOINT + "/disposer/", // 処分事業者編集 /disposer/{id}
    DETAIL: process.env.VUE_APP_API_ENDPOINT + "/disposer/", // 処分事業者情報取得 /disposer/{id}
    SITE_EDIT: process.env.VUE_APP_API_ENDPOINT + "/disposer/site/", // 処分場編集 /disposer/site/{disposerId}
    SITE_SELECT_LICENCE:
      process.env.VUE_APP_API_ENDPOINT + "/disposal/site/select/licence", // 処分場登録時許可証選択肢取得
    LICENCE_DOWNLOAD:
      process.env.VUE_APP_API_ENDPOINT + "/disposal/licence/file/", // 処分許可証ファイルダウンロードAPI /disposal/licence/file/{id}
    MANIFEST_CSV_REGIST_VALIDATE: process.env.VUE_APP_API_ENDPOINT + "/disposal/manifest/csvregistvalidate", // マニフェストCSV一括登録バリデーションチェックAPI
    MANIFEST_CSV_REGIST: process.env.VUE_APP_API_ENDPOINT + "/disposal/manifest/csvregist", // マニフェストCSV一括登録API
    CONTRACT: process.env.VUE_APP_API_ENDPOINT + "/disposal/contract", // 運搬事業者契約情報詳細取得API
    CBACONTRACT_CREATE:
      process.env.VUE_APP_API_ENDPOINT + "/disposer/cbacontract", // CBA契約登録
    CBACONTRACT_EDIT:
      process.env.VUE_APP_API_ENDPOINT + "/disposer/cbacontract/", // CBA契約編集 /disposer/cbacontract/{id}
    CHECK_PROXY: process.env.VUE_APP_API_ENDPOINT + "/disposer/check/proxy", // 処分代理登録可否判定API
    GENERATE_SELECT_COMPANY: process.env.VUE_APP_API_ENDPOINT + "/disposal/generate/selectcompany", // 処分に紐づく排出事業者選択肢取得API
    SENDMAIL: process.env.VUE_APP_API_ENDPOINT + "/disposer/contract/sendmail", // 準備完了メール送付
    GENERATE_COMPANY_SELECT: process.env.VUE_APP_API_ENDPOINT + "/disposal/generate/selectcompany", // 処分に紐づく排出事業者選択肢取得API
    COLLECT_COMPANY_SELECT: process.env.VUE_APP_API_ENDPOINT + "/disposal/collect/selectcompany", // 処分に紐づく運搬事業者選択肢取得API
  },
  OPERATE: {
    SEARCH: process.env.VUE_APP_API_ENDPOINT + "/operate", // 処理事業者一覧検索API
    REGIST_COUNT: process.env.VUE_APP_API_ENDPOINT + "/operate/registstatus/count", // 処理契約登録状況別件数取得API
  },
  ENTRUST: {
    SEARCH: process.env.VUE_APP_API_ENDPOINT + "/entrust", // 委託契約書一覧検索API
    DETAIL: process.env.VUE_APP_API_ENDPOINT + "/entrust/", // 委託契約書情報取得PI /entrust/{id}
    CREATE: process.env.VUE_APP_API_ENDPOINT + "/entrust", // 委託契約書登録API
    UPDATE: process.env.VUE_APP_API_ENDPOINT + "/entrust", // 委託契約書有効無効化API（PATCH）,
    CBA_GENERATOR_COMPANY:
      process.env.VUE_APP_API_ENDPOINT + "/cba/generate/company", // CBA排出事業者選択肢取得API
    CBA_GENERATE_STORE:
      process.env.VUE_APP_API_ENDPOINT + "/cba/generate/store", // CBA排出事業場選択肢取得API
    SELECT_FACTORY:
      process.env.VUE_APP_API_ENDPOINT + "/generate/selectfactory/", // 排出事業所選択肢取得API /generate/selectfactory/{generatecompanyid}
    GENERATE_SELECT_COMPANY:
      process.env.VUE_APP_API_ENDPOINT + "/generate/selectcompany", // 排出事業者選択肢取得API
    COLLECT_SELECT_FACTORY:
      process.env.VUE_APP_API_ENDPOINT + "/collect/selectfactory/", // 運搬事業者別JWNETID選択肢取得API /collect/selectfactory/{collectCompanyId}
    TRANSSHIPMENT_BY_LICENCE:
      process.env.VUE_APP_API_ENDPOINT + "/collect/licence/transshipment", // 積替保管場所選択肢取得API
    DISPOSAL_SITE_BY_LICENCE:
      process.env.VUE_APP_API_ENDPOINT + "/disposal/licence/site", // 許可証別処分場選択肢取得API
    DISPOSAL_COMPANY:
      process.env.VUE_APP_API_ENDPOINT + "/cba/disposal/company", // CBA処分事業者選択肢取得API
    COLLECT_COMPANY: process.env.VUE_APP_API_ENDPOINT + "/cba/collect/company", // CBA運搬事業者選択肢取得API
    DISPOSAL_LICENCE:
      process.env.VUE_APP_API_ENDPOINT + "/licence/select/disposal/", // 処分許可証選択肢取得API /licence/select/disposal/{id}
    COLLECT_LICENCE:
      process.env.VUE_APP_API_ENDPOINT + "/licence/select/collect/", // 運搬許可証選択肢取得API /licence/select/collect/{id}
    DISPOSAL_COMMON_LICENCE:
      process.env.VUE_APP_API_ENDPOINT + "/licence/select/disposal/common/", // 一般廃棄物処分許可証選択肢取得API /licence/select/disposal/common/{id}
    COLLECT_COMMON_LICENCE:
      process.env.VUE_APP_API_ENDPOINT + "/licence/select/collect/common/", // 一般廃棄物運搬許可証選択肢取得API /licence/select/collect/common/{id}
    GET: process.env.VUE_APP_API_ENDPOINT + "/entrust/", // 委託契約書情報取得API /entrust/{id}
    EDIT: process.env.VUE_APP_API_ENDPOINT + "/entrust/", // 委託契約書編集API /entrust/{id}
    ROUTE_LIST: process.env.VUE_APP_API_ENDPOINT + "/route/list", // ルート選択肢取得
    ROUTE_LABELS: process.env.VUE_APP_API_ENDPOINT + "/route/labeldetail/", // ルートラベル /route/labeldetail/{id}
    ROUTE_INFO: process.env.VUE_APP_API_ENDPOINT + "/route/", // ルート情報 /route/{id}
    CHECK_START_DATE:
      process.env.VUE_APP_API_ENDPOINT + "/entrust/check/startdate", // 委託契約編集可能日判定API
    WASTE_INFO_WDS_DOWNLOAD:
      process.env.VUE_APP_API_ENDPOINT + "/entrust/wasteinfo/wdsfile/", // 委託契約廃棄物情報WDSファイルダウンロードAPI /entrust/wasteinfo/wdsfile/{id}
    COLLECT_CONTRACT_FILE_DOWNLOAD:
      process.env.VUE_APP_API_ENDPOINT + "/entrust/collectcontract/file/", // 運搬委託契約書ファイルダウンロードAPI /entrust/collectcontract/file/{id}
    DISPOSAL_CONTRACT_FILE_DOWNLOAD:
      process.env.VUE_APP_API_ENDPOINT + "/entrust/disposalcontract/file/", // 処分委託契約書ファイルダウンロードAPI /entrust/disposalcontract/file/{id}
    MATCHING: process.env.VUE_APP_API_ENDPOINT + "/entrust/matching", // 委託契約書突合API
    DISPOSAL_SITE_BY_COMPANYID:
      process.env.VUE_APP_API_ENDPOINT + "/disposal/selectsite/", // 処分場選択肢取得API /disposal/selectsite/{disposalcompanyid}
    JWNET_ITEM_TYPE:
      process.env.VUE_APP_API_ENDPOINT + "/entrust/jwnetitemtype", // 委託契約に紐づく廃棄物種類選択肢取得
    GENERATE_JWNET_ITEM_TYPE:
      process.env.VUE_APP_API_ENDPOINT + "/entrust/generate/jwnetitemtype/", //排出事業者に紐づく廃棄物種類選択肢取得API /api/entrust/generate/jwnetitemtype/{generateCompanyId}
    SELECT_FINAL_DISPOSAL_SITE: process.env.VUE_APP_API_ENDPOINT + "/disposal/selectfinaldisposalsite",
    CSV_VALIDATE: process.env.VUE_APP_API_ENDPOINT + "/entrust/csvregistvalidate", // 委託契約CSV一括登録バリデーションチェックAPI
    CSV_IMPORT: process.env.VUE_APP_API_ENDPOINT + "/entrust/csvimport", // 委託契約CSV一括登録API
    CSV_CONFIRM: process.env.VUE_APP_API_ENDPOINT + "/entrust/confirm", // 委託契約書CSV確認情報取得API
    DELIVERY_CHECKSHEET:
        process.env.VUE_APP_API_ENDPOINT + "/entrust/delivery/checksheet", // 委託契約の受渡確認票出力API （POST）
  },
  LICENCE: {
    SEARCH: process.env.VUE_APP_API_ENDPOINT + "/licence", // 許可証検索API
    EXPIRED_COUNT: process.env.VUE_APP_API_ENDPOINT + "/licence/expired/count", // 許可証期限切れ件数取得API
    DISPOSAL_LICENCE: process.env.VUE_APP_API_ENDPOINT + "/licence/disposal/", // 処分許可証情報取得API /licence/disposal/{id}
    COLLECT_LICENCE: process.env.VUE_APP_API_ENDPOINT + "/licence/collect/", // 運搬許可証情報取得API /licence/collect/{id}
    COMMON_DISPOSAL_LICENCE: process.env.VUE_APP_API_ENDPOINT + "/licence/disposal/common/", // 一般廃棄物処分許可証情報取得API /licence/disposal/common/{id}
    COMMON_COLLECT_LICENCE: process.env.VUE_APP_API_ENDPOINT + "/licence/collect/common/", // 一般廃棄物運搬許可証情報取得API /licence/collect/common/{id}
    MUNICIPALITY: process.env.VUE_APP_API_ENDPOINT + "/licence/municipality", // 自治体取得API
  },
  TOTALIZATION: {
    JWNET_ITEM: process.env.VUE_APP_API_ENDPOINT + "/totalization/jwnetitem", // 廃棄物種類別数量集計取得  /totalization/jwnetitem?type=${type}"
    REGIST_COUNT:
      process.env.VUE_APP_API_ENDPOINT + "/totalization/wellfest/regist/count", // Wellfest利用件数取得
  },
  ACCOUNT_MANAGEMENT: {
    ACCOUNT: process.env.VUE_APP_API_ENDPOINT + "/account", // アカウント一覧検索
    POST: process.env.VUE_APP_API_ENDPOINT + "/account", // アカウント登録
    EDIT: process.env.VUE_APP_API_ENDPOINT + "/account/", // アカウント編集 /account/{id}
    DELETE: process.env.VUE_APP_API_ENDPOINT + "/account/", // アカウント削除 /account/{id}
    COMPANY_LIST: process.env.VUE_APP_API_ENDPOINT + "/account/companylist" // アカウントによりアカウント画面用の事業者を取得API
  },
  ROUTE: {
    LIST: process.env.VUE_APP_API_ENDPOINT + "/route/list/", // ルート選択肢取得API /route/list/{generateStoreId}
    COLLECT_LIST: process.env.VUE_APP_API_ENDPOINT + "/collect/route/list/", // 運搬代理登録ルート選択肢取得 /collect/route/list/{generateStoreId}
    DETAIL: process.env.VUE_APP_API_ENDPOINT + "/route/", // ルート詳細 /route/{id}
    SEARCH: process.env.VUE_APP_API_ENDPOINT + "/route", // ルート検索
    EDIT: process.env.VUE_APP_API_ENDPOINT + "/route/", // ルート情報編集 /route/{id}
    ACTIVATE: process.env.VUE_APP_API_ENDPOINT + "/route", // ルート有効・無効化API
    FAVORITE: process.env.VUE_APP_API_ENDPOINT + "/route/favorite/", //ルートお気に入り登録 /route/favorite/{id}
    LABEL_DETAIL: process.env.VUE_APP_API_ENDPOINT + "/route/labeldetail/", // ルートラベル /route/labeldetail/{id}
    DUPLICATE: process.env.VUE_APP_API_ENDPOINT + "/route/duplicate/", // ルート複製 /route/duplicate/{id}
    ROUTE_SHAPE_TYPE: process.env.VUE_APP_API_ENDPOINT + "/route/shapetype/", // ルート荷姿選択肢取得API /route/shapetype/{routeId}
    ROUTE_DISPOSAL_WAY_TYPE:
      process.env.VUE_APP_API_ENDPOINT + "/route/disposalwaytype/", // ルート処分方法選択肢取得API /route/disposalwaytype/{routeId}
    SELECT_FINAL_DISPOSALSITE:
      process.env.VUE_APP_API_ENDPOINT + "/entrust/selectfinaldisposalsite/", // 委託契約に紐づく最終処分場選択肢取得API /api/entrust/selectfinaldisposalsite/{entrustid}
    ROUTE_PRIORCONSULTATION_ALERT: process.env.VUE_APP_API_ENDPOINT + "/route/priorconsultation/alert", // ルートに紐づく事前協議書のアラート情報取得API /route/priorconsultation/alert
    CSV_UPLOAD: process.env.VUE_APP_API_ENDPOINT + "/route/csvupload",
    CSV_DOWNLOAD: process.env.VUE_APP_API_ENDPOINT + "/route/csvdownload",
  },
  WASTEITEM: {
    CBAITEM: process.env.VUE_APP_API_ENDPOINT + "/cbaitem", // CBA品目登録
    CBAITEM_EDIT: process.env.VUE_APP_API_ENDPOINT + "/cbaitem/", // CBA品目編集 /cbaitem/{id}
    WASTENAME: process.env.VUE_APP_API_ENDPOINT + "/wastename", // 廃棄物名称
    WASTENAME_EDIT: process.env.VUE_APP_API_ENDPOINT + "/wastename/", // 廃棄物名称 /wastename/{id}
  },
  PRIORCONSULTATION: {
    SEARCH: process.env.VUE_APP_API_ENDPOINT + "/priorconsultation", // 事前協議書検索API
    ROUTE_SEARCH: process.env.VUE_APP_API_ENDPOINT + "/priorconsultation/route/list", // 事前協議書ルート検索API
    DETAIL: process.env.VUE_APP_API_ENDPOINT + "/priorconsultation/", // 事前協議書取得API /priorconsultation/{id}
    DELETE: process.env.VUE_APP_API_ENDPOINT + "/priorconsultation/", // 事前協議書削除API /priorconsultation/{id}
    CREATE: process.env.VUE_APP_API_ENDPOINT + "/priorconsultation", // 事前協議書登録API
    EDIT: process.env.VUE_APP_API_ENDPOINT + "/priorconsultation/", // 事前協議書編集API /priorconsultation/{id}
    FILE: process.env.VUE_APP_API_ENDPOINT + "/priorconsultation/file/", // 事前協議書ファイルダウンロードAPI /priorconsultation/file/{id}
  },
  SHAREINFO: {
    CREATE: process.env.VUE_APP_API_ENDPOINT + "/generate/share/info", // 共有情報登録API
    DETAIL: process.env.VUE_APP_API_ENDPOINT + "/generate/share/info/", // 共有情報取得API /generate/share/info/{id}
    SEARCH: process.env.VUE_APP_API_ENDPOINT + "/generate/share/info?", // 共有情報検索API
    EDIT: process.env.VUE_APP_API_ENDPOINT + "/generate/share/info/", // 共有情報編集API /generate/share/info/{id}
    DELETE: process.env.VUE_APP_API_ENDPOINT + "/generate/share/info/", // 共有情報削除API /generate/share/info/{id}
    DOWNLOAD: process.env.VUE_APP_API_ENDPOINT + "/generate/share/info/sharefile/", // 共有情報ファイルダウンロードAPI /generate/share/info/sharefile/{id}
  },
  CONTRACT: {
    INPUT_MEMBER: process.env.VUE_APP_API_ENDPOINT + "/generate/share/inputMember/list", // 共有情報登録時担当者選択肢取得API
    COLLECT_COMPANY:
      process.env.VUE_APP_API_ENDPOINT +
      "/generate/contract/collectcompany/list", // 契約に紐づく運搬事業者選択肢取得API
    MEMBER:
      process.env.VUE_APP_API_ENDPOINT + "/generate/contract/allmember/list", // 契約に紐づく担当者選択肢取得API
    DISPOSAL_COMPANY:
      process.env.VUE_APP_API_ENDPOINT +
      "/generate/contract/disposalcompany/list", // 契約に紐づく処分事業者選択肢取得API
  },
  ZIPCODE: {
    SEARCH: process.env.VUE_APP_API_ENDPOINT + "/zipcode", // 郵便番号データ取得API
    DOWNLOAD: process.env.VUE_APP_API_ENDPOINT + "/zipcode/file/", // 郵便番号データファイルダウンロードAPI /zipcode/file/{id}
    UPLOAD: process.env.VUE_APP_API_ENDPOINT + "/zipcode/csvupload"// 郵便番号データファイルアップロードAPI
  },
  DUPLICATION: {
    NAME: process.env.VUE_APP_API_ENDPOINT + "/duplication/name/check", // 所属名による名称が重複チェック
  }
};

export const WEEK_OF_DAY = [
  {
    id: 1,
    label: "日",
  },
  {
    id: 2,
    label: "月",
  },
  {
    id: 3,
    label: "火",
  },
  {
    id: 4,
    label: "水",
  },
  {
    id: 5,
    label: "木",
  },
  {
    id: 6,
    label: "金",
  },
  {
    id: 7,
    label: "土",
  },
];

export const DOMAIN = {
  ACCOUNT_TYPE: {
    ADMIN: 1, // 管理者
    GENERAL: 2, // 一般
  },
  BUSINESS_TYPE: {
    CBA: 1, // CBA
    GENERATOR: 2, // 排出事業者
    COLLECTOR: 3, // 運搬事業者
    DISPOSER: 4, // 処分事業者
    OPERATOR: 5, // 処理事業者
  },
  CONTRACT_STATUS_TYPE: {
    NOT_CONTRACT: 1, // 未契約
    PREPARATION: 10, // 契約準備中
    CONTRACT: 20, // 契約中
    EXPIRED: 80, // 契約期限切れ
    END: 90, // 契約終了
  },
  USER_TYPE: {
    CBA: 10, // CBA運用担当者,
    GENERATE_CONTRACTOR: 20, // 排出契約者
    GENERATE_COMPANY: 21, // 排出事業者
    GENERATE_FACTORY: 22, // 排出事業所
    GENERATE_STORE: 23, // 排出事業場
    COLLECT_COMPANY: 30, // 運搬事業者
    COLLECT_FACTORY: 31, // 運搬事業所
    COLLECT_STORE: 32, // 運搬営業所
    DISPOSAL_COMPANY: 40, //処分事業者
    DISPOSAL_SITE: 41, //処分事業場
  },
  MANIFEST_STATUS: {
    NOT_RESERVE: 0, // 未予約
    UNDER_RESERVE: 10, // 予約登録中
    RESERVED: 11, // 予約済み
    RESERVE_PENDING: 12, // 予約承認待ち
    RESERVE_DENIAL: 19, // 予約否認
    UNDER_ISSUE: 20, // 交付登録中
    ISSUED: 21, // 交付済み
    MANIFEST_FIX_PENDING: 22, // マニフェスト修正承認待ち
    MANIFEST_FIXING: 23, // 修正中
    ISSUED_FIX_DENIAL: 25, // 修正否認
    UNDER_COLLECT_REPORT: 30, // 運搬終了報告中
    COLLECT_REPORTED: 31, // 運搬終了報告済み
    UNDER_COLLECT_REPORT_FIX: 32, // 運搬終了報告修正中
    COLLECT_REPORT_FIX_PENDING: 33, // 運搬終了報告修正承認待ち
    COLLECT_REPORT_DELETE_PENDING: 34, // 運搬終了報告取消承認待ち
    UNDER_COLLECT_REPORT_DELETE: 35, // 運搬終了報告取消中
    COLLECT_REPORT_FIX_DENIAL: 39, // 運搬終了報告修正否認
    UNDER_DISPOSAL_REPORT: 40, // 処分終了報告中
    DISPOSAL_REPORTED: 41, // 処分終了報告済み
    UNDER_DISPOSAL_REPORT_FIX: 42, // 処分終了報告修正中
    DISPOSAL_REPORT_FIX_PENDING: 43, // 処分終了報告修正承認待ち
    DISPOSAL_REPORT_DELETE_PENDING: 44, // 処分終了報告取消承認待ち
    DISPOSAL_REPORT_FIX_DENIAL: 49, // 処分終了報告修正否認
    UNDER_FINAL_DISPOSAL_REPORT: 50, // 最終処分終了報告中
    FINAL_DISPOSAL_REPORTED: 51, // 最終処分終了報告済み
    UNDER_FINAL_DISPOSAL_REPORT_FIX: 52, // 最終処分終了報告修正中
    FINAL_DISPOSAL_REPORT_FIX_PENDING: 53, // 最終処分終了報告修正承認待ち
    FINAL_DISPOSAL_REPORT_FIX_DENIAL: 59, // 最終処分終了報告修正否認
    COMPLETE: 80, // 完了
    DELETE: 90, // 削除
    UNDER_DELETE: 91, // 削除中
  },
  COLLECT_MANIFEST_STATUS: {
    UNDER_COLLECT_REPORT: 30, //運搬終了報告中
    COLLECT_REPORTED: 31, //運搬終了報告済み
    UNDER_COLLECT_REPORT_FIX: 32, //運搬終了報告修正中
    COLLECT_REPORT_FIX_PENDING: 33, //運搬終了報告修正承認待ち
    COLLECT_REPORT_DELETE_PENDING: 34, //運搬終了報告取消承認待ち
    UNDER_COLLECT_REPORT_DELETE: 35, // 運搬終了報告取消中
    COLLECT_REPORT_FIX_DENIAL: 39, //運搬終了報告修正否認
    NOT_REPORT: 70, //未報告
  },
  RECURRING_TYPE: {
    DAY: 1, // 日ごと
    WEEK: 2, // 週間ごと
  },
  MANIFEST_SEARCH_CONDITION: {
    ALL: 10, // 全て
    BEFORE_ISSUE: 20, // 交付前_引き渡し日入力済み
    BEFORE_ISSUE_NODATE: 21, // 交付前_引き渡し日未入力
    ISSUED: 30, // 進行中
    COMPLETE: 40, // 完了
    BEFORE_REPORT: 50, // 終了報告前_終了報告待ち
    BEFORE_REPORT_NODATE: 51, // 終了報告前_引き渡し日未設定
    AFTER_REPORT: 60, // 終了報告済み
  },
  MANIFEST_SEARCH_TYPE: {
    COLLECTION_DATE_ALERT: 10, // 引き渡し日期限注意
    COLLECTION_DATE_EXPIRED: 11, // 引き渡し日期限切れ
    BEFORE_REPORT: 20, // 終了報告修正承認待ち
    MANIFEST_FIX_DENIAL: 30, // マニフェスト修正否認
    REPORT_DATE_ALERT: 40, // 終了報告期限注意
    REPORT_DATE_EXPIRED: 41, // 終了報告期限切れ
    FIX_PENDING: 50, // 処理業者承認/否認待ち
    FINAL_DISPOSAL_NG: 60 // 最終処分NG
  },
  CONTRACT_SEARCH_STATUS: {
    ACTIVE: 10, // 有効
    ALERT_ACTIVE: 20, // 有効（注意）
    INVALID: 80, // 無効
    CLOSE_INVALID: 90, // 無効（契約終了）
  },
  LICENCE_STATUS: {
    ACTIVE: 10, // 使用中
    WARNING: 20, // 期限切れ注意
    UPDATING: 30, // 更新中
    EXPIRED: 90, // 期限切れ
  },
  TARGET_LICENCE: {
    COLLECTOR: 1, // 運搬
    DISPOSER: 2, // 処分
    ENTRUST: 3, // 委託契約中
  },
  LICENCE_TYPE: {
    COLLECTOR: 1, // 運搬
    DISPOSER: 2, // 処分
  },
  GENERATE_CONTRACT_SEARCH_STATUS: {
    NOT_ENTRUST: 10, // 委託契約未登録
    MAIL_NOTSETTING: 20, // 準備完了メール未設定
    MAIL_RESERVED: 30, // 準備完了メール予約中
    COMPLETE: 40, // 完了
  },
  WASTE_TYPE: {
    INDUSTRIAL_WASTE: 1, // 産業廃棄物
    VALUABLES: 2, // 有価
    COMMON_WASTE: 3, // 一般廃棄物
    SPECIAL_INDUSTRIAL_WASTE: 4, //特別管理産業廃棄物
    NOT_SPECIAL_INDUSTRIAL_WASTE: 5, //普通業廃棄物
  },
  JWNET_ITEM_TYPE_MANAGEMENT: {
    COMMON: 1, //通常産廃
    SPECIAL: 2, //特別管理産廃
  },
  DESTINATION_TYPE: {
    TRANSSHIPMENT: 1, // 積替保管
    DISPOSAL_SITE: 2, // 処分場
  },
  MANIFEST_TYPE: {
    DIGITAL: 1, // 電子マニフェスト
    ANALOG: 2, // 紙マニフェスト
  },
  MANIFEST_CHECKSHEET_DOWNLOAD_TYPE: {
    PDF_ONE_SHEET: 1, // pdfダウンロード(単票)
    PDF_THREE_SHEET: 2, // pdfダウンロード(連票)
    EXCEL_ZIP: 3, // excelダウンロード
    BUNDLE_PDF: 4, // PDFダウンロード(複数一括)
  },
  ROUTE_MODAL_WASTE_MANIFEST_TYPE: {
    INDUSTRIAL_WASTE_DIGITAL: 1, // 産業廃棄物電子
    COMMON_WASTE_DIGITAL: 2, // 一般廃棄物電子
    VALUABLES_DIGITAL: 3, // 有価廃棄物電子
    INDUSTRIAL_WASTE_DIGITAL_ANALOG: 4 // 産業廃棄物紙
  },
  INDUSTRIAL_WASTE_JWNET_TYPE: {
    IS_NOT_SPECIAL_INDUSTRIAL_WASTE: 0, // 普通産業廃棄物
    IS_SPECIAL_INDUSTRIAL_WASTE: 1 // 特管産業廃棄物
  },
  TERM_DATE_TYPE: {
    COLLECTION_DATE: 1, // 運搬
    DISPOSAL_DATE: 2, // 処分
    FINAL_DISPOSAL_DATE: 3 // 最終処分
  },
  LABEL_TYPE: {
    STRING_TYPE: 1, // テキスト
    NUMBER_TYPE: 2, // 数値
  },
  DISPOSAL_MANIFEST_SEARCH_TYPE: {
    REPORT_DATE_ALERT: 10, // 終了報告期限注意
    REPORT_DATE_EXPIRED: 11, // 終了報告期限切れ
  },
  MANIFEST_REGIST_TYPE: {
    GENERATOR_REGIST: 0, // 排出で予約したマニフェスト
    COLLECTOR_REGIST: 1, // 運搬で予約したマニフェスト
    DISPOSER_REGIST: 2, // 処分で予約したマニフェスト
  },

  ENTRUST_REGIST_PROCESS_TYPE: {
    UNPROCESSED: 0,
    PROCESSING: 1,
    COMPLETE: 2,
    REGIST_ERROR: 3,
    SEND_MAIL_ERROR: 4,
    PDF_CREATE_ERROR: 5,
    BATCH_ERROR: 6,
  },
};

export const PAGE_PATH = {
  FORBIDDEN: "/error/403",
};

export const CUSTOM_MAX_INTERVAL = 5;
export const INT_MAX = 2147483647;
export const INT_MIN = -2147483648;
export const MANIFEST_MULTIPLE_SET_ROUTE_MAX = 10; // マニフェスト複数予約画面：ルート選択できる最大数

export const LEGAL_PERSONALITY_TYPE = {
  LOCATION: {
    BEFORE: "前",
    AFTER: "後",
  },
};

export const QUANTITY_CONFIRM_BUSSINESS_TYPE = {
  GENERATOR: "排出事業者",
  DISPOSER: "処分業者",
  COLLECTOR_SECTION_1: "収集運搬業者(区間1)",
  COLLECTOR_SECTION_2: "収集運搬業者(区間2)",
  COLLECTOR_SECTION_3: "収集運搬業者(区間3)",
  COLLECTOR_SECTION_4: "収集運搬業者(区間4)",
  COLLECTOR_SECTION_5: "収集運搬業者(区間5)"
};

export const REPORT_SELECTION = {
  COLLECT_REPORT_SELECTION: [
    { name: "全て未報告", value: "0" },
    { name: "一部未報告", value: "1" },
    { name: "全て報告済み", value: "2" }
  ],
  DISPOSAL_REPORT_SELECTION: [
    { name: "未報告", value: "0" },
    { name: "報告済み", value: "1"}
],
  FINAL_DISPOSAL_REPORT_SELECTION: [
    { name: "未報告", value: "0" },
    { name: "報告済み", value: "1"}
  ]
};

export const COLLECT_REPORTED_WAY_TYPE = {
  WELLFEST: { name: "wellfest", value: "1" },
  JWNET:  { name: "JWNET", value: "2"}
};

export const COLLECTOR_REGIST_TYPE = {
  JWNET: { name: "JWNETで登録", value: "0" },
  WELLFEST:  { name: "wellfestで登録", value: "1"}
};

// 設定した引き渡し担当方法
export const GENERATE_MEMBER_WAY_TYPE = {
  ID: { metaKey: "last_input_user_id"},
  TEXT: { metaKey: "last_input_user_text"},
};

export const DUPLICATION_CHECK = {
  NAME: [
    { displayName: "会社名/営業所名", targetId: 20},
    { displayName: "事業者名", targetId: 21},
    { displayName: "事業所名", targetId: 22},
    { displayName: "事業場名", targetId: 23},
    { displayName: "企業名", targetId: 30},
    { displayName: "事業所名", targetId: 31},
    { displayName: "営業所名", targetId: 32},
    { displayName: "会社名", targetId: 40},
    { displayName: "処分場名", targetId: 41},
  ]
};

export const JWNET_LINE_TYPE = [
    { name: "接続登録番号1（20741351）", value: 1 },
    { name: "接続登録番号2（20775303）", value: 2 },
];

export const PROHIBITED_CHARACTERS_REGEX = /['%"_,<>\t\n\r]/
export const QR_INFO_MAX_BYTE_SIZE = 215
export const QUANITTY_REGEX = /^(\d{1,5}|0)(\.\d{1,3})?$/
export const MANIFEST_DOWNLOAD_LIMIT_VALUE = 20000
